import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Styled from "styled-components"

import Layout from "../../../components/Layout"
import SEO from "../../../components/Seo"
import RuledBreak from "../../../components/RuledBreak"
import { ButtonTrace } from "../../../components/Button"

const Lnk = Styled(Link)`
    &:hover {
        div {
            opacity: 0.6;
        }
    }
`

const Trainings = ({ data }) => {
  const headerImg = data.header.edges[0].node.childImageSharp.fluid
  const groupImg = data.group.edges[0].node.childImageSharp.fluid
  const privateImg = data.private.edges[0].node.childImageSharp.fluid
  return (
    <Layout
      fluid={headerImg}
      alt={"Cali Reeves Fan Like a Pro"}
      height={400}
      mobile={300}
    >
      <SEO title="Education" keywords={["Education", "Learning"]} />
      {/* ----------------------------------------------------------------------- */}
      <div className="row my-5">
        <div className="col-md-12 py-3">
          <RuledBreak>Trainings</RuledBreak>
        </div>
      </div>
      <div className="row text-center">
        <div className="col-8 col-md-6 col-lg-4 mr-auto ml-auto py-3">
          <h2>Group Trainings</h2>
          <Lnk to="/education/trainings/group">
            <Img
              fluid={groupImg}
              alt="Education - Group Training"
              imgStyle={{ objectFit: "contain" }}
            />
          </Lnk>
          <ButtonTrace to="/education/trainings/group" btn={3} className="mt-2">
            See Group Trainings
          </ButtonTrace>
        </div>
        <div className="col-8 col-md-6 col-lg-4 mr-auto ml-auto py-3">
          <h2>Private Trainings</h2>
          <Lnk to="/education/trainings/private">
            <Img
              fluid={privateImg}
              alt="Education - Private Training"
              imgStyle={{ objectFit: "contain" }}
            />
          </Lnk>
          <ButtonTrace
            to="/education/trainings/private"
            btn={3}
            className="mt-2"
          >
            See Private Trainings
          </ButtonTrace>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query trainingsQuery {
    header: allFile(filter: { name: { eq: "home_header_1200_791" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }

    group: allFile(filter: { name: { eq: "group_trainings_600x600" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }

    private: allFile(filter: { name: { eq: "private_trainings_600x600" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
  }
`

export default Trainings
